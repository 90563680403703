<blockable-div #container>
  @if(form){
  <gms-waiver-header [waiver]="waiver" class="mt-4"></gms-waiver-header>
  <form [formGroup]="form">
    <div class="grid grid-nogutter">
      <div class="col-6">
        <gms-waiver-details [waiver]="waiver"></gms-waiver-details>
      </div>
      <div class="col-6">
        <div class="mb-3 text-xl">Waiver Assignment</div>
        <div class="field mb-3">
          <label for="durationMonths" class="block text-900 font-medium mb-2">Waiver Duration *</label>
          <p-dropdown [options]="durations" formControlName="durationMonths" placeholder="Duration In Months" optionLabel="label" optionValue="value"
                      [editable]="false" appendTo="body" styleClass="w-full" inputStyleClass="w-full"></p-dropdown>
        </div>
        <div class="field mb-3">
          <label for="waiverAcceptanceRemarks" class="block text-900 font-medium mb-2">Waiver Acceptance Remarks *</label>
          <textarea id="waiverAcceptanceRemarks" rows="10" cols="30" pTextarea formControlName="waiverAcceptanceRemarks" class="w-full"
                    placeholder="Please add the final remarks, usually summarizing how the Waiver is accepted. Again this will be stored for further Auditing purposes."></textarea>
        </div>
        <div class="field mb-3">
          <label for="policyDefinitionId" class="block text-900 font-medium mb-2">Policy Definition Id</label>
          <input id="policyDefinitionId" formControlName="policyDefinitionId" type="text" pInputText placeholder="" class="w-full">
        </div>
        <div class="field mb-3">
          <label for="deploymentId" class="block text-900 font-medium mb-2">Deployment Id</label>
          <input id="deploymentId" formControlName="deploymentId" type="text" pInputText placeholder="" class="w-full">
        </div>
      </div>
    </div>
    <div class="text-right">
      <p-button label="Cancel" icon="pi pi-times" class="m-3" [outlined]="true" (click)="cancel()"></p-button>
      <p-button label="Assign" icon="pi pi-check" class="m-3" [disabled]="!form.valid" (onClick)="save()"></p-button>
    </div>
    <p-blockUI [target]="container" [blocked]="formBlocked">
      <i class="pi pi-cloud-upload" style="font-size: 3rem"></i>
    </p-blockUI>
  </form>
  }
  @else{
  <div class="flex justify-content-center m-8">
    <i class="pi pi-rotate pi-microchip text-blue-900" style="font-size: 3rem"></i>
  </div>
  }
</blockable-div>
