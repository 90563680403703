import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { ButtonGroupModule } from 'primeng/buttongroup';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { ControlErrorModule } from '../../../shared/directives/control-error.module';

import { BlockableDiv } from '../layout/components/blockable-div.component';
import { WaiverAcceptRiskDialogComponent } from './components/waiver-acceptrisk-dialog.component';
import { WaiverCompleteDialogComponent } from './components/waiver-complete-dialog.component';
import { WaiverCreateDialogComponent } from './components/waiver-create-dialog.component';
import { WaiverDetailsComponent } from './components/waiver-details.component';
import { WaiverHeaderComponent } from './components/waiver-header.component';
import { WaiverInfoDialogComponent } from './components/waiver-info-dialog.component';
import { WaiverReviewDialogComponent } from './components/waiver-review-dialog.component';
import { WaiverListComponent } from './components/waiver-list.component';
import { WaiverRoutingModule } from './waiver-routing.module';
import { InputSwitchModule } from 'primeng/inputswitch';


@NgModule({
  declarations: [
    WaiverListComponent,
    WaiverCreateDialogComponent,
    WaiverReviewDialogComponent,
    WaiverAcceptRiskDialogComponent,
    WaiverCompleteDialogComponent,
    WaiverHeaderComponent,
    WaiverDetailsComponent,
    WaiverInfoDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    DropdownModule,
    TableModule,
    ToastModule,
    InputTextModule,
    DynamicDialogModule,
    BlockUIModule,
    ControlErrorModule,
    MultiSelectModule,
    ToggleButtonModule,
    ButtonGroupModule,
    BlockableDiv,
    OverlayPanelModule,
    InputSwitchModule,

    WaiverRoutingModule
  ]
})
export class WaiverModule {
}
