import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { enumValues } from '../../../../shared/utils';

import { debounceTime, distinctUntilChanged, merge } from 'rxjs';
import { TenantDto } from '../../../services/api/configuration.model';
import { ConfigurationService } from '../../../services/api/configuration.service';
import { ProjectDto } from '../../../services/api/project.model';
import { ProjectService } from '../../../services/api/project.service';
import { GmsStore } from '../../../services/gms.store';
import { WaiverState } from '../api/waiver-enums.model';
import { WaiverListFilter } from '../api/waiver-list-filters.model';
import { WaiverDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';
import { WaiverAcceptRiskDialogComponent } from './waiver-acceptrisk-dialog.component';
import { WaiverCompleteDialogComponent } from './waiver-complete-dialog.component';
import { WaiverCreateDialogComponent } from './waiver-create-dialog.component';
import { WaiverInfoDialogComponent } from './waiver-info-dialog.component';
import { WaiverReviewDialogComponent } from './waiver-review-dialog.component';


@Component({
  templateUrl: './waiver-list.component.html',
  providers: [DialogService, ConfirmationService],
  styles: ['']
})
export class WaiverListComponent implements OnInit {

  @ViewChild('dt') dt: Table | undefined;

  filterStatuses: string[] = enumValues(WaiverState);
  filterProjects: ProjectDto[] = [];
  filterTenants: TenantDto[] = [];

  items: WaiverDto[] = [];
  filteredItems: WaiverDto[] = [];

  waiverStates = WaiverState;
  filters: FormGroup;

  priviledgedUser: boolean = false;
  userProjects: string[] = [];

  loading: boolean = true;

  constructor(
    private waiverService: WaiverService,
    private configurationService: ConfigurationService,
    private dialogService: DialogService,
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private projectService: ProjectService,
    store: GmsStore
  ) {

    store.gmsUser$.subscribe(u => {
      this.priviledgedUser = u?.privilegedUser ?? false;
      this.userProjects = u?.memberInProjects ?? [];
    });

    const filter = new WaiverListFilter({
      states: [WaiverState.Active, WaiverState.InReview, WaiverState.WaitingCompletion, WaiverState.WaitingRiskAcceptance] //WaiverState.Rejected, 
    });
    this.filters = this.fb.group({
      myRequests: [filter.myRequests || true],
      search: [filter.search || null],
      states: [filter.states || []],
      projectId: [filter.projectId],
      tenant: [filter.tenant]
    });

    this.filters.get('search')?.valueChanges.subscribe(value => this.searchItem(value));
    merge(
      this.filters.get('projectId')!.valueChanges,
      this.filters.get('tenant')!.valueChanges,
      this.filters.get('myRequests')!.valueChanges,
      this.filters.get('states')!.valueChanges
    ).pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => this.refreshItems());
  }

  async ngOnInit() {
    this.projectService.projects().subscribe(r => this.filterProjects = r);
    this.configurationService.tenants().subscribe(r => this.filterTenants = r);
    this.refreshItems();

    this.route.queryParams.subscribe(params => {
      const search = params['search'] || null;
      const action = params['action'] || null;
      if (search) {
        this.filters.patchValue({ search, myRequests:false });
      }
      if (action) {
        switch (action) {
          case 'create':
            this.waiverCreateDialog();
            break;
        }
      }
    });
  }

  refreshItems() {
    this.loading = true;
    this.waiverService.getAllWaivers(this.filters.value).subscribe(r => {
      this.items = r;
      this.filteredItems = r;
      if (this.filters.get('search')?.value) {
        this.searchItem(this.filters.get('search')?.value);
      }
      this.loading = false;
    });
  }

  searchItem(searchValue: string) {
    if (!searchValue) {
      this.filteredItems = this.items;
    } else {
      this.filteredItems = this.items.filter(item =>
        item.ticketNumber.includes(searchValue) || item.waiverId.includes(searchValue)
      );
    }
  }

  filtersClear() {
    this.filters.patchValue(new WaiverListFilter({ /*pageSize: this.tablePageSize*/ }));
  }

  waiverCreateDialog(projectId?: string, waiverId?: string) {
    const dialog = this.dialogService
      .open(WaiverCreateDialogComponent, {
        data: { projectId, waiverId },
        header: waiverId ? `Waiver Edit # ${waiverId}` : `Waiver Create`,
        width: '900px'
      });

    dialog.onClose
      .subscribe(() => {
        this.refreshItems();
      });
  }

  waiverReviewDialog(projectId:string, waiverId:string) {
    const dialog = this.dialogService
      .open(WaiverReviewDialogComponent, {
        data: { projectId, waiverId },
        header: `Waiver Review # ${waiverId}`,
        width: '900px'
      });

    dialog.onClose
      .subscribe(() => {
        this.refreshItems();
      });
  }

  waiverAcceptRiskDialog(projectId: string, waiverId: string) {
    const dialog = this.dialogService
      .open(WaiverAcceptRiskDialogComponent, {
        data: { projectId, waiverId },
        header: `Waiver Accept Risk # ${waiverId}`,
        width: '900px'
      });

    dialog.onClose
      .subscribe(() => {
        this.refreshItems();
      });
  }

  waiverCompleteDialog(projectId: string, waiverId: string) {
    const dialog = this.dialogService
      .open(WaiverCompleteDialogComponent, {
        data: { projectId, waiverId },
        header: `Waiver Complete # ${waiverId}`,
        width: '900px'
      });

    dialog.onClose
      .subscribe(() => {
        this.refreshItems();
      });
  }

  waiverInfoDialog(projectId: string, waiverId: string) {
    const dialog = this.dialogService
      .open(WaiverInfoDialogComponent, {
        data: { projectId, waiverId },
        header: `Waiver Info # ${waiverId}`,
        width: '700px'
      });
  }

  waiverStateText(state: WaiverState): { text: string, class: string } {
    switch (state) {
      case WaiverState.Rejected:
        return { text: 'Rejected', class: 'text-red-600' };
      case WaiverState.InReview:
        return { text: 'In Review', class: 'text-blue-500' };
      case WaiverState.WaitingRiskAcceptance:
        return { text: 'Waiting Risk Acceptance', class: 'text-blue-400' };
      case WaiverState.WaitingCompletion:
        return { text: 'Waiting Completion', class: 'text-blue-700' };
      case WaiverState.Active:
        return { text: 'Active', class: 'text-green-600' };
      case WaiverState.Expired:
        return { text: 'Expired', class: 'text-gray-200' };
      default:
        return { text: 'Unknown', class: 'text-black-500' };
    }
  }

  //editRule(ruleId: string = '') {
  //  this.router.navigate(['../edit', ruleId], { relativeTo: this.route })
  //}
}

