
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { NotificationService } from '../../../../shared/services/notification.service';
import { formAddValidators } from '../../../../shared/utils';
import { ProjectDto } from '../../../services/api/project.model';
import { ProjectService } from '../../../services/api/project.service';
import { enumToOptionsArray } from '../../../services/utils';
import { EnvironmentType } from '../api/waiver-enums.model';
import { OptionModel, WaiverCreateDto } from '../api/waiver.model';
import { WaiverService } from '../api/waiver.service';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { forkJoin, lastValueFrom } from 'rxjs';
import { GmsStore } from '../../../services/gms.store';

@Component({
  templateUrl: './waiver-create-dialog.component.html',
  providers: [ConfirmationService]
})
export class WaiverCreateDialogComponent implements OnInit {

  waiverId!: string;
  projectId!: string;

  model: WaiverCreateDto = new WaiverCreateDto();

  validators: ValidationRule[] = [];
  form?: FormGroup = undefined;
  formBlocked = false;

  projects: ProjectDto[] = [];
  durations: OptionModel[] = [];
  policies: OptionModel[] = [];
  environments: OptionModel[] = enumToOptionsArray(EnvironmentType);

  constructor(
    private fb: FormBuilder,
    private projectService: ProjectService,
    private waiverService: WaiverService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private notify: NotificationService,
    private store: GmsStore
  ) {
    this.waiverId = this.config.data.waiverId;
    this.projectId = this.config.data.projectId;
  }

  async ngOnInit() {
    const user = this.store.current.gmsUser;

    // Create an object to hold all parallel requests
    const requests: any = {
      validators: this.waiverService.getValidator('create'),
      projects: this.projectService.projects(),
      durations: this.waiverService.getOptions('duration-months'),
      policies: this.waiverService.getOptions('policies')
    };

    // Add waiver request conditionally if waiverId exists
    if (this.waiverId) {
      requests.waiver = this.waiverService.getWaiverById(this.projectId, this.waiverId);
    }

    // Execute all requests in parallel and wait for them to complete
    const results = await lastValueFrom(forkJoin(requests)) as any;

    // Set properties from the results
    this.validators = results.validators;
    this.durations = results.durations;
    this.policies = results.policies;
    this.projects = (results.projects as ProjectDto[]).filter(p => user?.memberInProjects.includes(p.projectId));

    // Set model from waiver if available
    if (results.waiver) {
      this.model = new WaiverCreateDto(results.waiver);
    }

    this.form = formAddValidators(this.fb.group(this.model), this.validators);
  }
  
  policyChanged(event: DropdownChangeEvent) {
    const policy = this.policies.find(t => t.label == event.value);
    this.form?.patchValue({ policyDefinitionId: policy?.value });
  }

  cancel() {
    this.ref.close();
  }

  save() {
    this.formBlocked = true;
    const projectId = this.form?.get('projectId')?.value;
    this.waiverService.
      createWaiver(projectId, this.form?.value)
      .subscribe({
        next: r => {
          if (r) {
            this.notify.success('Saved')
            this.ref.close(r);
          } else {
            this.notify.error('Failed', r);
          }
          this.formBlocked = false;
        },
        error: e => this.formBlocked = false
      });
  }
}

