
import { Component, Input, OnInit } from '@angular/core';
import { WaiverDto } from '../api/waiver.model';

@Component({
  selector: 'gms-waiver-details',
  templateUrl: './waiver-details.component.html',
})
export class WaiverDetailsComponent implements OnInit {

  @Input() waiver: WaiverDto = new WaiverDto();
  constructor() {
  }

  async ngOnInit() {
  }
}

