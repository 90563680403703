import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { ResultModel } from '../../../services/api/common.model';
import { GmsStore } from '../../../services/gms.store';
import { OptionModel, ValidateOwnershipRequest, WaiverAcceptRiskDto, WaiverCompleteDto, WaiverCreateDto, WaiverDetailsDto, WaiverDto, WaiverReviewDto } from './waiver.model';
import { WaiverListFilter } from './waiver-list-filters.model';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { GmsConfigService } from '../../../services/gms-configuration.service';


export const WaiverApiUrl = {
  getWaiversList: () => `/waivers`,
  getProjectWaivers: (projectId: string) => `/projects/${projectId}/waivers`,
  getWaiverById: (projectId: string, waiverId: string) => `/projects/${projectId}/waivers/${waiverId}`,
  validateOwnership: () => `/waivers/validate/ownership`,
  createWaiver: (projectId: string) => `/projects/${projectId}/waivers`,
  reviewWaiver: (projectId: string, waiverId: string) => `/projects/${projectId}/waivers/${waiverId}/review`,
  acceptRiskWaiver: (projectId: string, waiverId: string) => `/projects/${projectId}/waivers/${waiverId}/accept-risk`,
  completeWaiver: (projectId: string, waiverId: string) => `/projects/${projectId}/waivers/${waiverId}/complete`,

  getOptions: (type: string) => `/waivers/options/${type}`,
  getValidator: (method: string) => `/waivers/validator/${method}`,
}


@Injectable({ providedIn: 'root' })
export class WaiverService extends BaseApiService {

  constructor(config: GmsConfigService, store: GmsStore) {
    super(config, store);
  }

  getAllWaivers(filter: WaiverListFilter): Observable<WaiverDto[]> {
    return super.post<WaiverListFilter, WaiverDto[]>(WaiverApiUrl.getWaiversList(), filter);
  }

  getProjectWaivers(projectId: string): Observable<WaiverDto[]> {
    return super.get<WaiverDto[]>(WaiverApiUrl.getProjectWaivers(projectId));
  }

  getWaiverById(projectId: string, waiverId: string): Observable<WaiverDto> {
    return super.get<WaiverDto>(WaiverApiUrl.getWaiverById(projectId, waiverId));
  }

  createWaiver(projectId: string, request: WaiverCreateDto): Observable<WaiverDto> {
    return super.post<WaiverCreateDto, WaiverDto>(WaiverApiUrl.createWaiver(projectId), request);
  }

  reviewWaiver(projectId: string, waiverId: string, request: WaiverReviewDto): Observable<WaiverDto> {
    return super.put<WaiverReviewDto, WaiverDto>(WaiverApiUrl.reviewWaiver(projectId, waiverId), request);
  }

  acceptRiskWaiver(projectId: string, waiverId: string, request: WaiverAcceptRiskDto): Observable<WaiverDto> {
    return super.put<WaiverAcceptRiskDto, WaiverDto>(WaiverApiUrl.acceptRiskWaiver(projectId, waiverId), request);
  }

  completeWaiver(projectId: string, waiverId: string, request: WaiverCompleteDto): Observable<WaiverDto> {
    return super.put<WaiverCompleteDto, WaiverDto>(WaiverApiUrl.completeWaiver(projectId, waiverId), request);
  }


  validateOwnership(request: ValidateOwnershipRequest): Observable<ResultModel> {
    return super.post<ValidateOwnershipRequest, ResultModel>(WaiverApiUrl.validateOwnership(), request);
  }

  getOptions(type: string): Observable<OptionModel[]> {
    return super.get<OptionModel[]>(WaiverApiUrl.getOptions(type));
  }

  getValidator(method: string): Observable<ValidationRule[]> {
    return super.get<ValidationRule[]>(WaiverApiUrl.getValidator(method));
  }
}
