import { UserDto } from "../../../services/api/user.model";
import { EnvironmentType, WaiverState } from "./waiver-enums.model";
import { v4 as uuidv4 } from 'uuid';


export class WaiverDto {
  waiverId: string = uuidv4();
  tenant: string = '';
  waiverType: string = 'Policy';
  ticketNumber: string = '';
  projectId: string = '';
  description: string = '';
  durationMonths: number = 0;
  environmentType?: EnvironmentType;
  policyDescription: string = '';
  policyDefinitionId: string = '';
  resourceGroupName: string = '';
  subscriptionId: string = '';
  deploymentId: string = '';
  createdAt: Date = new Date();
  createdBy: string = '';
  expiresAt: Date = new Date();
  rejectionReason: string = '';
  state?: WaiverState;

  createdByUser?: UserDto;

  details?: WaiverDetailsDto;

  timestamp?: Date;

  constructor(partial?: Partial<WaiverDto>) {
    Object.assign(this, partial);
  }
}


export class WaiverCreateDto {
  waiverId: string = uuidv4();
  ticketNumber: string = '';
  projectId: string = '';
  description: string = '';
  durationMonths: number = 0;
  environmentType: EnvironmentType | null = null;
  policyDescription: string = '';
  policyDefinitionId: string = '';
  resourceGroupName: string = '';
  subscriptionId: string = '';

  timestamp?: Date;

  constructor(partial?: Partial<WaiverCreateDto>) {
    Object.assign(this, partial);
  }
}

export class WaiverReviewDto {
  riskAssessmentRequired: boolean = false;
  riskAssessmentDescription: string = '';
  securityAssessmentRequired: boolean = false;
  reviewerAcceptsRuleBook: boolean = false;
  reviewerConfirmsAlignmentsWithArchitects: boolean = false;
  rejectionReason: string = '';

  constructor(partial?: Partial<WaiverReviewDto>) {
    Object.assign(this, partial);
  }
}

export class ValidateOwnershipRequest {
  resourceGroupName: string = '';
  subscriptionId: string = '';

  constructor(partial?: Partial<ValidateOwnershipRequest>) {
    Object.assign(this, partial);
  }
}



export class WaiverDetailsDto {
  waiverId: string = '';
  riskAssessmentRequired: boolean = false;
  riskAssessmentDescription: string = '';
  securityAssessmentRequired: boolean = false;
  reviewedBy?: string;
  reviewedAt?: Date;
  reviewerAcceptsRuleBook: boolean = false;
  reviewerConfirmsAlignmentsWithArchitects: boolean = false;
  riskAcceptedBy?: string;
  riskAcceptedAt?: Date;
  riskAcceptanceRemarks: string = '';
  riskAcceptorAuthorizedToAccept: boolean = false;
  riskAcceptorConfirmsSecurityAssessmentIsDone: boolean = false;
  waiverCompletedBy?: string;
  waiverCompletedAt?: Date;
  waiverAcceptanceRemarks: string = '';

  reviewedByUser?: UserDto;
  riskAcceptedByUser?: UserDto;
  waiverCompletedByUser?: UserDto;

  constructor(partial?: Partial<WaiverDetailsDto>) {
    Object.assign(this, partial);
  }
}


export class WaiverAcceptRiskDto {
  riskAcceptanceRemarks: string = '';
  riskAcceptorAuthorizedToAccept: boolean = false;
  riskAcceptorConfirmsSecurityAssessmentIsDone: boolean = false;

  constructor(partial?: Partial<WaiverAcceptRiskDto>) {
    Object.assign(this, partial);
  }
}

export class WaiverCompleteDto {
  //Duration of the waiver in months. Valid values: 1, 3, 6, 12.
  durationMonths: number = 0;

  //Remarks for waiver acceptance.
  waiverAcceptanceRemarks: string = '';

  //Identifier for the deployment.
  deploymentId: string = '';

  //Identifier for the policy definition.
  policyDefinitionId: string = '';

  constructor(partial?: Partial<WaiverCompleteDto>) {
    Object.assign(this, partial);
  }
}

export class OptionModel {
  label: string = '';
  value: string = '';

  constructor(partial?: Partial<OptionModel>) {
    Object.assign(this, partial);
  }
}
