@if(waiver){
<table class="w-full border-collapse text-sm">
  <tbody>
    <!--<tr>
    <td class="font-bold p-2 bg-gray-50 w-1/3">Waiver ID</td>
    <td class="p-2">{{waiver.waiverId}}</td>
  </tr>-->
    <tr>
      <td class="font-bold p-2 bg-gray-100">Created</td>
      <td class="p-2">
        {{waiver.createdAt | date:'medium'}}<br />
        {{waiver.createdByUser?.displayName || waiver.createdBy}}
      </td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Ticket Number</td>
      <td class="p-2">{{waiver.ticketNumber}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Project ID</td>
      <td class="p-2">{{waiver.projectId}} ({{waiver.tenant}})</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Resource</td>
      <td class="p-2">{{waiver.resourceGroupName}}<br />{{waiver.subscriptionId}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Description</td>
      <td class="p-2">{{waiver.description}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Duration | Expiration</td>
      <td class="p-2">{{waiver.durationMonths}}M | {{waiver.expiresAt | date:'short'}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Environment Type</td>
      <td class="p-2">{{waiver.environmentType}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Policy Description</td>
      <td class="p-2">{{waiver.policyDescription}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Policy Definition</td>
      <td class="p-2">{{waiver.policyDefinitionId.split('/').pop()}}</td>
    </tr>

    <!-- Waiver Details Section -->
    @if(waiver.details){
    @if(waiver.details.reviewedAt){
    <tr class="border-top-1">
      <td class="font-bold p-2 bg-gray-100">Reviewed</td>
      <td class="p-2">
        {{waiver.details.reviewedAt | date:'medium'}}<br />
        {{waiver.details.reviewedByUser?.displayName || waiver.details.reviewedBy}}
      </td>
    </tr>
    @if(waiver.state == 'Rejected'){
    <tr>
      <td class="font-bold p-2 bg-gray-50">Rejection Reason</td>
      <td class="p-2">{{waiver.rejectionReason}}</td>
    </tr>
    }
    @else{
    <tr>
      <td class="font-bold p-2 bg-gray-50">Risk Assessment Required</td>
      <td class="p-2">{{waiver.details.riskAssessmentRequired ? 'Yes' : 'No'}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Risk Assessment Description</td>
      <td class="p-2">{{waiver.details.riskAssessmentDescription}}</td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Security Assessment Required</td>
      <td class="p-2">{{waiver.details.securityAssessmentRequired ? 'Yes' : 'No'}}</td>
    </tr>
    }
    }
    @if(waiver.details.riskAcceptedAt){
    <tr class="border-top-1">
      <td class="font-bold p-2 bg-gray-100">Risk Accepted</td>
      <td class="p-2">
        {{waiver.details.riskAcceptedAt | date:'medium'}}<br />
        {{waiver.details.riskAcceptedByUser?.displayName || waiver.details.riskAcceptedBy}}
      </td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Risk Acceptance Remarks</td>
      <td class="p-2">{{waiver.details.riskAcceptanceRemarks}}</td>
    </tr>
    }
    @if(waiver.details.waiverCompletedAt){
    <tr class="border-top-1">
      <td class="font-bold p-2 bg-gray-100">Waiver Completed</td>
      <td class="p-2">
        {{waiver.details.waiverCompletedAt | date:'medium'}}<br />
        {{waiver.details.waiverCompletedByUser?.displayName || waiver.details.waiverCompletedBy}}
      </td>
    </tr>
    <tr>
      <td class="font-bold p-2 bg-gray-50">Waiver Acceptance Remarks</td>
      <td class="p-2">{{waiver.details.waiverAcceptanceRemarks}}</td>
    </tr>
    }
    }
  </tbody>
</table>
}
@else{
<div class="flex justify-content-center">
  <i>No waiver data available</i>
</div>
}
